import React from "react";
import { FaXTwitter, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa6";
import TwitterXLogo from "../assets/social_media/logo-black.png"
import InstagramLogo from "../assets/social_media/Instagram_Glyph_Gradient.png"
import YoutubeLogo from "../assets/social_media/yt_icon_rgb.png"
import LinkedInLogo from "../assets/social_media/LI-In-Bug.png"

const SocialMediaContainer = () => {
    return (
        <div className="mt-6 mb-6 text-center">
            <p className="text-sm text-gray-600">Follow us on social media:</p>
            <div className="flex justify-center items-center space-x-6 mt-2 mb-2">
                {/* X (Twitter) icon */}
                <a 
                    href="https://x.com/360_tiptop" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-3xl text-[#556789] hover:text-gray-700 transform transition-transform duration-300 hover:scale-110"
                >
                    <img 
                        src={TwitterXLogo} 
                        alt="X (Twitter) Logo" 
                        className="w-8"
                        loading="lazy"
                    />
                </a>
                {/* Instagram icon */}
                <a 
                    href="https://www.instagram.com/360minutestt/" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-3xl text-[#556789] hover:text-gray-700 transform transition-transform duration-300 hover:scale-110"
                >
                    <img 
                        src={InstagramLogo} 
                        alt="Instagram Logo" 
                        className="w-8"
                        loading="lazy"
                    />
                </a>
                {/* YouTube icon */}
                <a 
                    href="https://www.youtube.com/channel/UC36lOvSqknammKmmcUvDHmw" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-3xl text-[#556789] hover:text-gray-700 transform transition-transform duration-300 hover:scale-110"
                >
                    <img 
                        src={YoutubeLogo} 
                        alt="Youtube Logo" 
                        className="w-8"
                        loading="lazy"
                    />
                </a>
                {/* LinkedIn icon */}
                <a 
                    href="https://www.linkedin.com/company/tiptop-technologies" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-3xl text-[#556789] hover:text-gray-700 transform transition-transform duration-300 hover:scale-110"
                >
                    <img 
                        src={LinkedInLogo} 
                        alt="LinkedIn Logo" 
                        className="w-8"
                        loading="lazy"
                    />
                </a>
            </div>

            <label htmlFor="tiptop_url" className="text-sm text-gray-700">
                <span>360</span> is a <a 
                    href="https://mind360.ai/" 
                    className="text-[#ff2a2f] hover:underline hover:text-[#ff4f55] hover:font-bold" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    TipTop Technologies
                </a> production.
            </label>
            
        </div>
    );
};

export default SocialMediaContainer;
