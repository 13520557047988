import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import logo from '../assets/GenZ_Logo.svg';
import poster from '../assets/360_poster.png'
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import { ContentCopy as ContentCopyIcon, Link as LinkIcon } from '@mui/icons-material';
import SocialMediaContainer from '../components/SocialMediaContainer';
import {jsPDF} from 'jspdf';
import { saveAs } from 'file-saver'; 

function ReferralCode() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [message, setMessage] = useState('Link expired! Please try again.');
  const [referralCode, setReferralCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [copied, setCopied] = useState(false);

  const referralLink = `https://genz-staging2.feeltiptop.com/${referralCode}`;

  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    axios.get(`https://genz-staging2.feeltiptop.com/api/confirm-email/${token}`)
      .then(response => {
        setMessage(response.data.message);
        setSuccess(response.data.success);
        setReferralCode(response.data.referral_code);
      })
      .catch(err => {
        console.log(err);
        setMessage('The confirmation link is invalid or expired.');
        setSuccess(false);
      });
  }, [token]);

  const goToHome = () => {
    navigate('/');
  };

  // Function to download the poster as a PDF
  const downloadPosterPDF = (event) => {
    try {
      event.preventDefault();
      
      const doc = new jsPDF('p', 'in', 'letter');
      doc.addImage(poster, 'PNG', 0, 0, 8.5, 11); //x, y, w and h in inches
  
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(5.95, 0.4, 1.8, 1.8, 0.393701, 0.393701, 'F');
      const qrCanvas = document.querySelector('.qr-code-canvas');
      
      if (qrCanvas) {
        try {
          const qrDataUrl = qrCanvas.toDataURL('image/png', 1.0);
          doc.addImage(qrDataUrl, 'PNG', 6.1, 0.55, 1.5, 1.5);
        } catch (error) {
          console.error("Failed to convert QR code canvas to image:", error);
          alert("Error generating QR code. Please try again.");
          return;
        }
      } else {
        console.warn("QR code canvas not found.");
        alert("Error generating QR code. Please try again.");
        return;
      }
  
      try {
        const pdfBlob = doc.output('blob');
  
        // Use FileSaver.js to trigger the download
        if (window.saveAs) {
          saveAs(pdfBlob, '360_Referral_Poster.pdf');
        } else {
          // Fallback to creating a download link for Safari
          const link = document.createElement('a');
          link.href = URL.createObjectURL(pdfBlob);
          link.download = '360_Referral_Poster.pdf';
  
          // Append the link to the body (required for Safari compatibility)
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          // Clean up the object URL
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.error("Error saving PDF:", error);
        alert("Error downloading the poster. Please try again.");
      }
    } catch (error) {
      console.error("Error in downloadPosterPDF function:", error);
      alert("There was an error preparing the poster. Please try again.");
    }
  };

  // Function to copy the referral code to clipboard
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-start p-4 sm:p-6 md:p-8 overflow-auto">
      <a href="/" className="logo-container mb-6">
        <img src={logo} alt="GenZ Logo" className="w-16 sm:w-20 md:w-24" loading="lazy" />
      </a>
      {success ? (
        <div className="max-w-xl mx-auto bg-gray-50 p-8 rounded-lg shadow-xl text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-600 mb-6">Here's your Referral Code!</h2>
          <p className="text-xl sm:text-2xl text-gray-800 mb-8">
            We’re thrilled to have you on board and can’t wait to show you what’s coming next!
          </p>

          <p className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
            Share Your Referral Code with Others:
          </p>

          <div className="flex items-center justify-center text-xl sm:text-2xl font-bold mb-4 cursor-pointer text-[#ff4f55] hover:text-[#ff2a2f] mr-2">
            <span className="cursor-pointer text-[#ff4f55] hover:text-[#ff2a2f] mr-2"
            onClick={() => copyToClipboard(referralCode)}>
              {referralCode}
            </span>
            <ContentCopyIcon 
              title="Copy Referral Code"
              onClick={() => copyToClipboard(referralCode)}
              className="cursor-pointer text-[#ff4f55] hover:text-[#ff2a2f] mr-2"
            />
          </div>

          <p className="text-md sm:text-lg text-gray-600 mb-4">
            Give this code to your friends so they can sign up and be part of our exclusive launch!
          </p>

          <div className="flex items-center justify-center text-xl sm:text-2xl font-bold mb-4 cursor-pointer text-[#ff4f55] hover:text-[#ff2a2f] mr-2">
            <a 
              className="relative flex items-center cursor-pointer text-[#ff4f55] hover:text-[#ff2a2f] mr-2 group" 
              onClick={() => copyToClipboard(referralLink)}
            >
              <QRCodeCanvas
                value={referralLink}
                size={200}
                fgColor="#ff4f55"
                bgColor="#f9fafb"
                level="H"
                className="shadow-md rounded-lg"
              />
              <QRCodeCanvas // QR Code for Poster Rendering
                value={referralLink}
                size={400}
                fgColor="#ff4f55"   // Set QR code color to black
                bgColor="#ffffff" // Set background to transparent
                level="H"
                className="qr-code-canvas"
                style={{ display: 'none' }} // Hide the QR code canvas
              />
              <div 
                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '8px',
                }}
              >
                <ContentCopyIcon 
                  title="Visit Referral Link"
                  style={{
                    fontSize: '32px',
                    color: '#ff4f55',
                  }}
                />
              </div>
            </a>
          </div>

          <p className="text-sm xs:text-md text-gray-500 mb-4">
            Don’t worry, we've sent you a link to your referral code so you can view it anytime!
          </p>

          {/* Marketing Tools Section */}
          <div className="mt-6">
            <h3 className="text-lg sm:text-l font-semibold text-gray-800 mb-4">
              Spread the Word with Our Marketing Tools:
            </h3>
            <a
              target="_blank"
              download="360_Referral_Poster.pdf"
              onClick={downloadPosterPDF} // Trigger the PDF download on click
              className="cursor-pointer inline-block w-full p-3 bg-[#ff2a2f] text-white font-semibold rounded-lg hover:bg-[#ff4f55] mb-4"
            >
              Download Printable Poster
            </a>
            <p className="text-sm xs:text-md text-gray-500">
              Download this poster and share it in your community — it is linked to your unique referral code! Simply print it out and help spread the word effortlessly.
            </p>
          </div>

          {/* Social media icons section */}
          <SocialMediaContainer></SocialMediaContainer>
        </div>
      ) : (
        <div className="max-w-xl mx-auto bg-gray-50 p-8 rounded-lg shadow-xl text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-600 mb-6">{message}</h2>
          <button onClick={goToHome} className="w-full p-3 bg-[#ff2a2f] text-white font-semibold rounded-lg hover:bg-[#ff4f55]">
            Go to Home
          </button>
          {/* Social media icons section */}
          <SocialMediaContainer></SocialMediaContainer>
        </div>
        
      )}

      <div
        className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 px-6 py-3 bg-[#ff4f55] text-white rounded-lg shadow-lg transition-transform duration-300 ease-in-out 
        ${copied ? 'translate-y-0' : 'translate-y-[140%]'}`}
        style={{
          transitionDuration: '300ms',
        }}
      >
        Copied to clipboard!
      </div>
    </div>
  );
}

export default ReferralCode;
