import React, { useState, useEffect } from "react";

const Step1 = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [investment, setInvestment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(""); // State for email error
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isValidInvestment, setIsValidInvestment] = useState(true);

  // Validate investment amount
  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    setInvestment(value);
    setIsValidInvestment(value && !isNaN(value) && parseFloat(value) >= 50);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(validateName(e.target.value));
  };
  
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError(validateName(e.target.value));
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/; // Allows single space between words
    if (!name.trim()) return "Name is required.";
    if (name.length < 2) return "Name must be at least 2 characters.";
    if (name.length > 50) return "Name must be less than 50 characters.";
    if (!nameRegex.test(name)) return "Only letters and single spaces allowed.";
    return "";
  };

  // Basic email validation
  const isValidEmail = email.includes("@") && email.includes(".");

  // Function to check for duplicate email in real-time
  useEffect(() => {
    const checkEmailAvailability = async () => {
      if (email && isValidEmail) {
        try {
          const response = await fetch(`https://genz-staging2.feeltiptop.com/api/check-email?email=${email}`);
          
          if (!response.ok) {
            throw new Error('Error: ' + response.statusText);  // Handle non-2xx responses
          }
          
          const data = await response.json();
          if (data.exists) {
            setEmailError(data.message); // Set email error if email exists
          } else {
            setEmailError(""); // Clear error if email is available
          }
        } catch (error) {
          console.error("Error checking email:", error);
          setEmailError("Error checking email availability. Please try again.");
        }
      } else {
        setEmailError(""); // Clear email error if email is invalid
      }
    };    

    // Debounce the email check
    const debounceTimeout = setTimeout(checkEmailAvailability, 2000);

    return () => clearTimeout(debounceTimeout);
  }, [email]); // Runs whenever the email changes

  const handleStripeCheckout = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch("https://genz-staging2.feeltiptop.com/api/create-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, firstName, lastName, investment }),
      });

      const data = await response.json();

      if (data.error) {
        if (data.error.includes("already registered")) {
          setEmailError(data.error); // Set email error
        } else {
          setError(data.error); // Set other errors
        }
      } else {
        const { url } = data;
        if (url) {
          window.location.href = url; // Redirect to Stripe Checkout
        } else {
          setError("Error: No session URL found.");
        }
      }
    } catch (error) {
      console.error("Unexpected error during Stripe checkout:", error);
      setError("Something went wrong! Please try again later.");
    }

    setLoading(false);
  };

  const isFormValid =
    isValidEmail &&
    email &&
    isValidInvestment &&
    investment &&
    firstName &&
    lastName &&
    !firstNameError &&
    !lastNameError &&
    !emailError; // Ensure no email error

  return (
    <div className="min-h-screen flex flex-col items-center justify-start p-4 sm:p-6 md:p-8 overflow-auto">
      <div className="max-w-xl mx-auto bg-gray-50 p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-6">
          TipTop Onboarding
        </h2>
        <p className="text-lg sm:text-xl text-gray-700 mb-6">
          Enter your details to proceed with payment.
        </p>

        {/* Email Input */}
        <div className="mb-4 text-left">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Email Address <span className="text-[#ff4f55]">*</span>
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
            placeholder="Enter your email"
          />
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p> // Display email error
          )}
        </div>

        {/* First Name Input */}
        <div className="mb-4 text-left">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            First Name <span className="text-[#ff4f55]">*</span>
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => handleFirstNameChange(e)}
            className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
            placeholder="Enter your first name"
          />
          {firstNameError && (
            <p className="text-red-500 text-sm mt-1">{firstNameError}</p> 
          )}
        </div>

        {/* Last Name Input */}
        <div className="mb-4 text-left">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Last Name <span className="text-[#ff4f55]">*</span>
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => handleLastNameChange(e)}
            className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
            placeholder="Enter your last name"
          />
          {lastNameError && (
            <p className="text-red-500 text-sm mt-1">{lastNameError}</p> 
          )}
        </div>


        {/* Investment Input */}
        <div className="mb-4 text-left">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Investment Amount ($50 minimum){" "}
            <span className="text-[#ff4f55]">*</span>
          </label>
          <input
            type="text"
            value={investment}
            onChange={handleInvestmentChange}
            className={`w-full p-4 h-14 border rounded-lg text-lg focus:outline-none focus:ring-2 ${
              isValidInvestment
                ? "border-gray-300 focus:ring-[#ff4f55]"
                : "border-red-500 focus:ring-red-500"
            }`}
            placeholder="Enter investment amount"
          />
          {!isValidInvestment && (
            <p className="text-red-500 text-sm mt-1">
              Investment must be at least $50.
            </p>
          )}
        </div>

        {/* Payment Button */}
        <button
          onClick={handleStripeCheckout}
          disabled={!isFormValid || loading}
          className={`w-full px-6 py-3 text-lg font-semibold rounded-lg transition duration-200 ${
            !isFormValid || loading
              ? "bg-gray-400 text-gray-200 cursor-not-allowed"
              : "bg-[#ff4f55] text-white hover:bg-[#e03e4d]"
          }`}
        >
          {loading ? "Processing..." : "Pay with Stripe"}
        </button>

        {/* Error Message */}
        {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default Step1;
