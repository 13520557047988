import { useState, useEffect } from "react";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [investment, setInvestment] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLasttName] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
  
    if (sessionId) {
      // Make an API call to verify the session and get metadata
      fetch(`https://genz-staging2.feeltiptop.com/api/verify-session?session_id=${sessionId}`)
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          if (data.success) {
            setEmail(data.email);
            setInvestment(data.investment);
            setFirstName(data.first_name);
            setLasttName(data.last_name);
            setStep(2);
          } else {
            alert("Payment verification failed. Please try again.");
            setStep(1);
          }
        })
        .catch(() => {
          alert("An error occurred while verifying payment.");
          setStep(1);
        });
    }
  }, []);
    
  return (
    <div className="min-h-screen flex flex-col items-center justify-start p-6">
      {step === 1 ? (
        <Step1
          setStep={setStep}
          email={email}
          setEmail={setEmail}
          investment={investment}
          setInvestment={setInvestment}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={firstName}
          setLastName={setLasttName}
        />
      ) : (
        <Step2 email={email} investment={investment} firstName={firstName} lastName={lastName} />
      )}
    </div>
  );
};

export default Onboarding;
