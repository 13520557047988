import { useState } from "react";
import { useNavigate } from "react-router-dom";
import agreementFile from "../assets/Tip Top - Consultant Confidentiality and Invention Assignment Agreement.DOC"; 

const Step2 = ({ email, investment, firstName, lastName }) => {
  const [resume, setResume] = useState(null);
  const [signedDoc, setSignedDoc] = useState(null);
  const [workSample, setWorkSample] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [agreementDownloaded, setAgreementDownloaded] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email || !investment || !firstName || !lastName || !resume || !workSample || !signedDoc) {
      setMessage("All fields are required.");
      return;
    }
  
    const formData = new FormData();
    formData.append("email", email);
    formData.append("investment", investment);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("resume", resume);
    formData.append("signed_doc", signedDoc);
    formData.append("work_sample", workSample);
  
    setIsProcessing(true);
    try {
      const response = await fetch("https://genz-staging2.feeltiptop.com/api/upload", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        if (response.status === 409 && errorResponse.error === "This email is already registered") {
          setMessage("You have already registered with this email.");
        } else {
          throw new Error(errorResponse.error || "Failed to upload files.");
        }
      } else {
        setMessage("Thank you for your submission! We'll review your materials and get back to you via email within 1 business day.");
      }
  
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      if (error.message === "Payment verification failed.") {
        navigate("/join360");
      }
    } finally {
      setIsProcessing(false);
    }
  };  

  return (
      <div className="max-w-xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-6">Upload Documents</h2>

        {/* Resume Upload */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Upload Resume <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            onChange={(e) => setResume(e.target.files[0])}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>

        {/* Work Sample Link */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Showcase Your Work (GitHub, Portfolio, LinkedIn, etc.) <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={workSample}
            onChange={(e) => setWorkSample(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
            placeholder="Link to Github, Portfolio, LinkedIn, etc."
          />
        </div>

        {/* Agreement Section */}
        <div className="mb-6 p-6 border border-gray-300 rounded-lg shadow-sm bg-white">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Confidentiality Agreement</h2>

          {/* Download Agreement */}
          <div className="mb-4">
            <label className="block text-lg text-gray-700">
              Download & Sign the Agreement <span className="text-[#ff4f55]">*</span>
            </label>
            <a
              href={agreementFile}
              download="Tip Top - Consultant Confidentiality and Invention Assignment Agreement.DOC"
              className="mt-2 text-[#ff4f55] underline hover:text-[#e03e4d] transition inline-flex items-center space-x-2"
              onClick={() => setAgreementDownloaded(true)}
            >
              <span>Download Agreement</span>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v12m0 0l-4-4m4 4l4-4M8 20h8"></path>
              </svg>
            </a>
          </div>

          {/* Divider */}
          <hr className="border-gray-300 my-4" />

          {/* Upload Signed Agreement */}
          <div>
            <label className="block text-lg text-gray-700">
              Upload Signed Agreement <span className="text-[#ff4f55]">*</span>
            </label>
            <input
              type="file"
              accept=".doc,.docx,.pdf"
              onChange={(e) => setSignedDoc(e.target.files[0])}
              className="mt-2 w-full p-4 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
            />
          </div>
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          disabled={
            !resume || !workSample || !signedDoc || !agreementDownloaded || isProcessing
          }
          className={`w-full px-6 py-3 text-lg font-semibold rounded-lg transition duration-200 ${
            !resume || !workSample || !signedDoc || !agreementDownloaded || isProcessing
              ? "bg-gray-400 text-gray-200 cursor-not-allowed"
              : "bg-red-500 text-white hover:bg-red-600"
          }`}
        >
          {isProcessing ? "Processing..." : "Submit"}
        </button>

        {message && <p className="mt-4 text-red-500 text-lg">{message}</p>}
      </div>
  );
};

export default Step2;
