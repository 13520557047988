import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import logo from "../assets/GenZ_Logo.svg";
import { useParams, useNavigate } from 'react-router-dom';
import SocialMediaContainer from '../components/SocialMediaContainer';

const SubscribeForm = () => {
  const { referralCode: referralCodeFromUrl } = useParams(); // Extract referral code from URL params
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    referralCode: referralCodeFromUrl || '', // Set initial referralCode from URL if available
    affiliation: '', 
    role: '',
    location: '',          
  });
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  // const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [isFormLocked, setIsFormLocked] = useState(false); // New state to lock form
  const [emailError, setEmailError] = useState('');


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
    return emailRegex.test(email);
  };

  const validateLocation = (value) => {
    const locationRegex = /^[a-zA-Z\s]+(,\s*[a-zA-Z\s]+){2}$/; // Format: City, State, Country
    return locationRegex.test(value) && value.length <= 100;
  };

  const validateAffiliation = (value) => {
    const affiliationRegex = /^[a-zA-Z0-9\s&.\-]+$/; // Allow letters, numbers, &, ., -, and spaces
    return (
      affiliationRegex.test(value) && // Matches allowed characters
      value.trim().length > 0 && // Ensure input is not just whitespace
      value.length <= 50 // Enforce maximum length
    );
  };

  const handleChange = (e) => {
    if (isFormLocked) return; // Prevent changes if form is locked

    const { name, value } = e.target;
    if (name === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Invalid email address'); // Set error if invalid
      } else {
        setEmailError(''); // Clear error if valid
      }
    }

    if (name === "affiliation") {
      if (validateAffiliation(value) || value === "") {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "location") {
      if (validateLocation(value) || value === "") {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setFormData({ ...formData, [name]: value });
  };

  // const handleCheckboxChange = (e) => {
  //   if (isFormLocked) return; // Prevent changes if form is locked

  //   setAgreedToTerms(e.target.checked);
  //   setMessage(null);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // if (!agreedToTerms) {
    //   setMessage('Please agree to the Terms and Conditions to continue.');
    //   setIsLoading(false); // Stop loading if there’s an error
    //   return;
    // }
    if (emailError) {
      setMessage('Please enter a valid email address.');
      setIsLoading(false);
      return;
    }

    // setMessage(null); // Clear any previous error message

    try {
      const response = await axios.post('https://genz-staging2.feeltiptop.com/api/subscribe-update', formData);
      if (response.data.success) {
        setMessage('Please check your inbox for the confirmation link.');
        setIsSignedUp(true);
        setConfirmationEmail(formData.email);
        startResendTimer();
        setIsFormLocked(true); // Lock the form after successful submit
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://genz-staging2.feeltiptop.com/api/resend-confirmation', formData);
      if (response.data.success) {
        setMessage(`Confirmation email resent to ${formData.email}.`);
        startResendTimer();
      } else {
        setMessage('Error resending confirmation email, ' + response.data.message + '. Please try again.');
      }
    } catch (error) {
      console.error('Error resending email:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const startResendTimer = () => {
    setResendTimer(30);
  };

  const removeReferralCode = () => {
    setFormData((prevData) => ({
      ...prevData,
      referralCode: '', // Remove the referral code from form data
    }));
    navigate('/');
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [resendTimer]);

  const isFormValid =
    formData.firstName.trim() !== '' &&
    formData.lastName.trim() !== '' &&
    formData.email.trim() !== '' &&
    !emailError
    // agreedToTerms;

  
  const roles = [
    "Student",
    "Professor",
    "University administrator",
    "School administrator", 
    "High school teacher",
    "Marketing & Sales", 
    "Finance", 
    "Business development", 
    "Business executive",
    "Tech", 
    "Data science", 
    "Entrepreneur",
    "Other"
  ];
    

  const educationRoles = [
    "Student",
    "Professor",
    "University administrator",
    "School administrator", 
    "High school teacher",
    // Other Educator jobs (maybe add Educator as an Other equvalent in this)
    // For newly added Education roles you need to add them to this list and the roles list above
  ];

  const roleOptions = roles.map((role) => ({ value: role, label: role }));

  return (
    <div className="min-h-screen flex flex-col items-center justify-start p-4 sm:p-6 md:p-8 overflow-auto">
      <div className="logo-container mb-6">
        {/* Make logo responsive */}
        <img src={logo} alt="GenZ Logo" className="w-16 sm:w-20 md:w-24" loading="lazy" />
      </div>
      <div className="max-w-xl mx-auto bg-gray-50 p-8 rounded-lg shadow-lg ">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-600 mb-6">Be First in Line</h2>
        <p className="text-xl sm:text-2xl text-gray-800 mb-8">Excited for what's coming? Enter your email to stay updated on our launch!</p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
          <label htmlFor="firstName" className="block text-lg font-semibold text-gray-700 mb-1 text-left">
    First Name <span className="text-[#ff4f55]">*</span>
  </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              placeholder="Your first name"
              onChange={handleChange}
              maxLength={50}
              className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
              disabled={isFormLocked} // Lock field if form is locked
              required
            />
          </div>
          <div className="mb-4">
          <label htmlFor="lastName" className="block text-lg font-semibold text-gray-700 mb-1 text-left">
    Last Name <span className="text-[#ff4f55]">*</span>
  </label>
            <input
              type="text"
              name="lastName"
              placeholder="Your last name"
              value={formData.lastName}
              onChange={handleChange}
              maxLength={50}
              className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
              disabled={isFormLocked} // Lock field if form is locked
              required
            />
          </div>
          <div className="mb-4">
          <label htmlFor="email" className="block text-lg font-semibold text-gray-700 mb-1 text-left">
    Email Address <span className="text-[#ff4f55]">*</span>
  </label>
            <input
              type="email"
              name="email"
              placeholder="Your email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
              disabled={isFormLocked} // Lock field if form is locked
              maxLength={254}
              required
            />
            {emailError && <p className="text-[#ff4f55] text-sm mt-2">{emailError}</p>} {/* Error message */}
          </div>

          {/* Role Dropdown */}
          <div className="mb-4">
            <label 
              htmlFor="role" 
              className="block text-lg font-semibold text-gray-700 mb-1 text-left"
            >
              Role
            </label>
            <Select
              id="role"
              options={roleOptions}
              value={roleOptions.find((option) => option.value === formData.role)}
              onChange={(selectedOption) =>
                handleChange({ target: { name: 'role', value: selectedOption?.value } })
              }
              isDisabled={isFormLocked}
              placeholder="Select role"
              classNamePrefix="react-select"
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: '0.5rem',
                  borderColor: '#D1D5DB',
                  boxShadow: 'none',
                  boxShadow: state.isFocused ? '0 0 0 2px #ff4f55' : 'none',
                  backgroundColor: isFormLocked ? '#F3F4F6' : 'white',
                  textAlign: 'left',
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: '0.5rem',
                  zIndex: 20,
                  textAlign: 'left',
                  backgroundColor: '#ff222',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#4B5563' : state.isFocused ? '#ff4f55' : 'white', // [#ff4f55] and gray-100
                  color: state.isSelected || state.isFocused ? 'white' : '#111827',
                  padding: '0.5rem 1rem',
                  textAlign: 'left',
                }),
              }}
            />
          </div>
    
            {/* Conditional Affiliation */}
            <div className="mb-4">
              <label htmlFor="affiliation" className="block text-lg font-semibold text-gray-700 mb-1 text-left ">
                {educationRoles.includes(formData.role) ? "University" : "Affiliation"}
              </label>
              <input
                type="text"
                name="affiliation"
                placeholder={educationRoles.includes(formData.role) ? "Your university" : "Your affiliation"}
                value={formData.affiliation}
                onChange={handleChange}
                maxLength={50} // Enforce character limit
                className={`w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]`}
                disabled={isFormLocked}
              />
              {!validateAffiliation(formData.affiliation) && formData.affiliation && ( // If this happens somehow its a fallback
                <p className="text-sm text-[#ff2a2f] mt-1 text-left">
                  Please enter a valid affiliation (letters, numbers, &, ., -, and spaces only, max 50 characters).
                </p>
              )}
            </div>
    
            {/* Location */}
            <div className="mb-4">
              <label htmlFor="location" className="block text-lg font-semibold text-gray-700 mb-1 text-left">
                Location
              </label>
              <input
                type="text"
                name="location"
                placeholder="City, State, Country"
                value={formData.location}
                onChange={handleChange}
                maxLength={100}
                className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
                disabled={isFormLocked}
              />
            </div>

          <div className="mb-4">
          <label htmlFor="referralCode" className="block text-lg font-semibold text-gray-700 mb-1 text-left">
    Referral Code 
  </label>
            <input
              type="text"
              name="referralCode"
              placeholder="Referral code (if any)"
              value={formData.referralCode}
              onChange={handleChange}
              maxLength={25}
              className="w-full p-4 h-14 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-[#ff4f55]"
              disabled={isFormLocked} // Lock field if form is locked
            />
          </div>

          {formData.referralCode && (
            <div className="mb-4 text-center">
              <p>Referral Code: {formData.referralCode}</p>
              <button
                type="button"
                onClick={removeReferralCode}
                hidden={isFormLocked}
                disabled={isFormLocked}
                className="text-[#ff2a2f] hover:text-[#ff4f55] font-bold"
              >
                Remove Referral Code
              </button>
            </div>
          )}

          {/* <div className="flex items-center mb-6">
            <input
              type="checkbox"
              id="terms"
              checked={agreedToTerms}
              onChange={handleCheckboxChange}
              className="mr-3"
              disabled={isFormLocked} // Lock checkbox if form is locked
              required
            />
            <label htmlFor="tiptop_url" className="text-sm text-gray-700">
            360 is a <a href="https://mind360.ai/" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">TipTop</a> production.
            </label>
          </div> */}

          {<p className="text-center mb-4 text-sm text-[#ff2a2f] hover:text-[#ff4f55] hover:underline hover:font-bold">
            <a href="https://www.youtube.com/shorts/e3hzxMEhzJ4" target="_blank" rel="noopener noreferrer">
              Here is how to sign up for our 360
            </a>
          </p>
          }

          {isSignedUp ? (
            <div className="w-full">
              <p className="text-center mb-4 text-sm text-gray-600">Confirmation email sent to: {confirmationEmail}</p>
              <button
                onClick={handleResendEmail}
                className={`w-full p-3 bg-[#ff2a2f] text-white font-semibold rounded-lg hover:bg-[#ff4f55] ${isLoading || resendTimer > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading || resendTimer > 0}
              >
                {resendTimer > 0 ? `Resend Email (${resendTimer}s)` : 'Resend Email'}
              </button>
            </div>
          ) : (
            <button
            type="submit"
            className={`w-full p-3 bg-[#ff2a2f] text-white font-semibold rounded-lg hover:bg-[#ff4f55] ${
              !isFormValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
                  disabled={!isFormValid || isLoading || isSignedUp}
              >
                        {isLoading ? 'Signing up...' : 'Sign Up'}
              </button>
          )}
        </form>

        {message && (
          <div
          className={`mt-6 text-center text-md font-semibold text-gray-600 ${
            message ? "" : "min-h-[20px]" // Set a minimal height when no message is present
          }`}
          >
            {message}
          </div>
        )}

        {/* Social media icons section */}
        <SocialMediaContainer></SocialMediaContainer>

        <p className="mt-6 text-center text-xs text-gray-600">
          We respect your privacy; this email will be used solely for launch updates and invitations to start building your profile with us.
        </p>
      </div>
    </div>
  );
};

export default SubscribeForm;
