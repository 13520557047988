import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SubscribeForm from "./routes/SubscribeForm";
import MainLayout from "./routes/MainLayout";
import ConfirmEmail from './routes/ConfirmEmail';
import ReferralCode from './routes/ReferralCode';
import BoardPart1 from './routes/OnBoard'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("REACT_APP_STRIPE_PUBLISHABLE_KEY");

function App() {
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<SubscribeForm />} />
              <Route path="/:referralCode" element={<SubscribeForm />} />
              <Route path="/referral/:token" element={<ReferralCode />} />
              <Route path="/email-confirmation/:token" element={<ConfirmEmail />} />
              <Route path="/Join360" element={<BoardPart1 />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </Route>
            <Route path="/static" element={<SubscribeForm />} />
          </Routes>
        </div>
      </Elements>
    </Router>
  );
}

export default App;
